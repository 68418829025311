/* eslint-disable react/jsx-filename-extension */
import React from 'react';

import Button from 'elements/Button';
import mainlogo from 'assets/images/logo.png';

export default function BrandIcon() {
  return (
    <Button
      className=""
      type="link"
      href="/"
    >
      <img src={mainlogo} alt="Hero" width={80} />
    </Button>
  );
}
